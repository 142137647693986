<script setup lang="ts">
import type { EditorialContentProps } from './EditorialContent.props'
import type { Brand } from '@design-system/index'
import type { Video } from '../UI/UIMedia.props'

const props = withDefaults(defineProps<EditorialContentProps>(), {
  logoSize: 's',
  contentAlignment: 'center',
  padding: 'default',
  firstPageContent: false,
  widthTextContainer: 100,
})
const shopTheLookModalIdRef = ref(null)
defineExpose({ shopTheLookModalIdRef })

const { breakpoint } = useProjectBreakpoint()
const isMobile = breakpoint.smaller('lg')

const animationContainer = ref(null)
const isAnimationContainerInViewport = ref(false)

const opacityGradientBottom = computed(() =>
  props.opacityGradient && props.opacityGradient.bottom > 0
    ? props.opacityGradient?.bottom / 10
    : 0
)
const opacityGradientMid = computed(() =>
  props.opacityGradient && props.opacityGradient.mid > 0
    ? props.opacityGradient?.mid / 10
    : 0
)
const opacityGradientTop = computed(() =>
  props.opacityGradient && props.opacityGradient.top > 0
    ? props.opacityGradient?.top / 10
    : 0
)
const widthTextContainer = computed(() => `${props.widthTextContainer}%`)

// We want to animate inner container when content is bottom aligned and is not an hero banner
const showAnimation =
  ['bottom-left', 'bottom-center'].includes(props.contentAlignment) &&
  !props.firstPageContent

const animationCss = computed(() => ({
  'animate-from-bottom': showAnimation,
  'is-in-viewport': isAnimationContainerInViewport.value,
}))

if (showAnimation) {
  useIntersectionObserver(animationContainer, ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isAnimationContainerInViewport.value = true
    }
  })
}

useState('hasHeroBanner', () => props.firstPageContent)

const paddingOptions = {
  s: 'p-4 lg:p-8',
  m: 'p-4 lg:p-12',
  l: 'p-4 lg:p-16',
  default: 'positive-padding py-4 md:py-12 lg:py-16',
}

const innerContainerAlignementOptions = {
  center: 'justify-center content-center text-center items-center',
  left: 'justify-start items-start align-middle my-auto',
  'bottom-left': 'justify-start align-bottom items-start',
  'bottom-center': 'justify-center items-center align-bottom text-center',
}

const outerContainerAlignementOptions = {
  center: 'justify-center content-center text-center items-center',
  left: 'justify-start content-start items-start align-middle',
  'bottom-left': 'items-end justify-start',
  'bottom-center': 'items-end justify-center',
}

const logoSizeOptions = {
  s: 'w-40 h-full',
  m: 'lg:w-full w-52 lg:max-w-[244px] h-full',
  l: 'lg:w-full w-64 lg:max-w-[400px] h-full',
}

const bgMobileMediaHasVideoControls = computed(() => {
  if (
    props.bgMobileMedia?.length !== 1 ||
    props.bgMobileMedia?.at(0)?.media?.type !== 'video'
  )
    return false
  const video = props.bgMobileMedia?.at(0)?.media as Video

  return video?.anatomy === 'standard'
})

const innerContainerClasses = computed(() => ({
  'lg:bottom-0 lg:top-0 lg:flex lg:items-end': showAnimation,
  'lg:justify-start': showAnimation && props.contentAlignment === 'bottom-left',
  'lg:justify-center':
    showAnimation && props.contentAlignment === 'bottom-center',
  'bottom-14 lg:bottom-0': bgMobileMediaHasVideoControls.value,
}))

const options = computed(() => {
  return {
    padding: paddingOptions[props.padding],
    innerContainerAlign:
      innerContainerAlignementOptions[props.contentAlignment],
    outerContainerAlign:
      outerContainerAlignementOptions[props.contentAlignment],
    logoSizeClass: logoSizeOptions[props.logoSize],
    desktopAspectRatio: props.desktopAspectRatio ?? '4/5',
    mobileAspectRatio: props.mobileAspectRatio ?? '4/5',
  }
})
const moleculesLinkWrapperMediaComponent = resolveComponent(
  'MoleculesLinkWrapperMedia'
)

const showInnerContainer = computed(
  () =>
    !!props.logoMedia?.length ||
    !!props.logo?.length ||
    (props.bgDesktopMedia && props.bgDesktopMedia?.length >= 2) ||
    (props.bgMobileMedia && props.bgMobileMedia?.length >= 2) ||
    props.title ||
    props.subtitle ||
    props.text ||
    !!props.linkLabel?.length
)
const { deferredTransparencyState } = useDefaultHeaderConfig()
</script>

<template>
  <div
    class="editorial-content outer-container relative flex w-full"
    :class="[
      options.outerContainerAlign,
      `!md:aspect-${options.desktopAspectRatio ?? '4/5'}`,
      `!aspect-${options.mobileAspectRatio ?? '4/5'}`,
      firstPageContent
        ? deferredTransparencyState.active
          ? 'editorial-first-content-header-transparent'
          : 'editorial-first-content'
        : 'h-full',
    ]"
  >
    <!-- Background image container -->
    <component
      :is="linkLabel?.length === 1 ? moleculesLinkWrapperMediaComponent : 'div'"
      :type="linkLabel?.length === 1 ? linkLabel[0].type : ''"
      :target="linkLabel?.length === 1 ? linkLabel[0].target : ''"
      :internal-link="linkLabel?.length === 1 ? linkLabel[0].internalLink : ''"
      :aria-label="linkLabel?.length === 1 ? linkLabel[0].ariaLabel : ''"
      class="media-container relative inset-0 z-10 h-full w-full"
    >
      <!-- Gradient Overlay -->
      <div
        class="gradient-overlay object-fit pointer-events-none absolute inset-0 z-50"
        :class="{ 'gradient-overlay': showGradient }"
        :style="{
          '--opacity-bottom': opacityGradientBottom,
          '--opacity-mid': opacityGradientMid,
          '--opacity-top': opacityGradientTop,
        }"
      />

      <div
        v-if="bgDesktopMedia && bgDesktopMedia.length > 1"
        class="desktop-media h-full w-full"
        :class="[bgMobileMedia?.length ? 'hidden lg:contents' : 'contents']"
      >
        <UISliderBasic
          id="editorial-content-desktop"
          :items="bgDesktopMedia"
          grap-cursor
          navigation-element-id="slider-pagination-desktop"
          :navigation-color="bulletPaginationColor"
          :breakpoints="{
            '(max-width: 1024px)': { active: false },
          }"
        >
          <template #slide="{ item }">
            <UIMedia
              v-bind="item"
              ref="shopTheLookModalIdRef"
              :aspect-ratio="options.desktopAspectRatio"
              class="h-full w-full shrink-0 grow-0 basis-full object-cover object-top"
            />
          </template>
        </UISliderBasic>
      </div>

      <div
        v-else-if="bgDesktopMedia?.length === 1"
        :class="[bgMobileMedia?.length ? 'hidden lg:contents' : 'contents']"
      >
        <UIMedia
          v-if="bgDesktopMedia?.[0]?.media"
          ref="shopTheLookModalIdRef"
          v-bind="{
            ...bgDesktopMedia[0],
            media: {
              ...bgDesktopMedia[0].media,
              ...(bgDesktopMedia[0].media.type === 'video' && {
                autoplay: !isMobile && bgDesktopMedia[0].media.autoplay,
              }),
            },
          }"
          :aspect-ratio="options.desktopAspectRatio"
          class="h-full w-full object-cover object-top"
        />
      </div>
      <div
        v-if="bgMobileMedia && bgMobileMedia.length > 1"
        class="mobile-media h-full w-full"
        :class="[bgDesktopMedia?.length ? 'contents lg:hidden' : 'contents']"
      >
        <UISliderBasic
          id="editorial-content-mobile"
          :items="bgMobileMedia"
          navigation-element-id="slider-pagination-mobile"
          :navigation-color="bulletPaginationColor"
          grap-cursor
          :breakpoints="{
            '(min-width: 1024px)': { active: false },
          }"
        >
          <template #slide="{ item }">
            <UIMedia
              v-bind="item"
              ref="shopTheLookModalIdRef"
              :aspect-ratio="options.mobileAspectRatio"
              class="h-full w-full shrink-0 grow-0 basis-full object-cover object-top"
            />
          </template>
        </UISliderBasic>
      </div>
      <div
        v-else-if="bgMobileMedia?.length === 1"
        :class="[bgDesktopMedia?.length ? 'contents lg:hidden' : 'contents']"
      >
        <UIMedia
          ref="shopTheLookModalIdRef"
          v-bind="{
            ...bgMobileMedia[0],
            media: {
              ...bgMobileMedia[0].media,
              ...(bgMobileMedia[0].media.type === 'video' && {
                autoplay: isMobile && bgMobileMedia[0].media.autoplay,
              }),
            },
          }"
          :aspect-ratio="options.mobileAspectRatio"
          class="h-full w-full object-cover object-top"
        />
      </div>
    </component>

    <!-- Inner Container -->
    <div
      v-if="showInnerContainer"
      class="inner-container pointer-events-none absolute z-10 min-w-[40%]"
      :class="innerContainerClasses"
      :style="{ width: widthTextContainer }"
    >
      <div
        ref="animationContainer"
        :class="{ 'lg:flex lg:h-2/3 lg:items-end': showAnimation }"
      >
        <div
          class="gap-sm lg:gap-md px-sm py-xxl lg:p-4xl flex flex-col"
          :class="[
            { 'lg:sticky lg:bottom-0': showAnimation },
            [options.innerContainerAlign, options.padding],
          ]"
        >
          <div v-if="logoMedia?.length">
            <UIMedia
              :media="logoMedia[0]"
              :class="[logoSizeOptions[logoSize]]"
            />
          </div>
          <div
            v-for="(singleLogo, index) in logo?.slice(0, 2)"
            v-else-if="logo && logo.length"
            :key="singleLogo + index"
            :class="`text-editorial-${logoColor}`"
          >
            <component
              :is="megaLogoMap[singleLogo as Brand]"
              :key="singleLogo"
              :class="[options.logoSizeClass]"
            />
          </div>

          <!-- Pagination bullet -->
          <div
            v-if="bgDesktopMedia && bgDesktopMedia?.length >= 2"
            id="slider-pagination-desktop"
            class="hidden lg:block"
          />

          <div
            v-if="bgMobileMedia && bgMobileMedia?.length >= 2"
            id="slider-pagination-mobile"
            class="lg:hidden"
          />

          <UtilsMarkdown
            v-if="title"
            :content="title"
            is-preprocessed
            container="h2"
            class="text-book-4 lg:text-book-3 uppercase"
            :class="[animationCss, [`text-editorial-${titleColor}`]]"
          />

          <UtilsMarkdown
            v-if="subtitle"
            :content="subtitle"
            is-preprocessed
            container="span"
            class="text-book-5 lg:text-book-4 uppercase"
            :class="[animationCss, `text-editorial-${subtitleColor}`]"
          />
          <span
            v-if="text"
            class="text-book-5"
            :class="[animationCss, `text-editorial-${textColor}`]"
          >
            <UtilsMarkdown :content="text" />
          </span>
          <div
            v-if="linkLabel?.length"
            class="pointer-events-auto flex flex-wrap items-center gap-6 align-middle"
            :class="[
              {
                'justify-center': props.contentAlignment.includes('center'),
                'animate-with-delay': showAnimation,
              },
              [animationCss],
            ]"
          >
            <UILink
              v-for="(link, index) in linkLabel.slice(0, 4)"
              :key="`${link.label}-${index}`"
              class="!text-link-5 flex shrink-0 flex-wrap text-center"
              :class="{ '!px-0': link.backgroundColor === 'transparent' }"
              v-bind="link"
              :data-testid="`link-to-plp-${index}`"
              :data-tracking="`CTA_${link.target}`"
              pos-tracking="hero"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gradient-overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, var(--opacity-bottom)) 0%,
    rgba(0, 0, 0, var(--opacity-mid)) 50%,
    rgba(0, 0, 0, var(--opacity-top)) 100%
  );
}

.animate-from-bottom {
  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 1s ease,
    opacity 1s ease;

  &.animate-with-delay {
    transition-delay: 1s;
  }

  &.is-in-viewport {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
